import { Component, Input, inject, viewChild } from '@angular/core';
import moment from 'moment';
import { StateComponent, ViewQuery } from '../model';
import { WorkflowService } from '../../workflow/workflow.service';
import { pivotConfig, pivotHeader } from './pivot-utils';
import { AppConfigService } from '../../app-config.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { FlexmonsterPivotModule } from 'ngx-flexmonster';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'ft-pivot-table',
    templateUrl: './ft-pivot-table.component.html',
    styleUrls: ['./ft-pivot-table.component.scss'],
    imports: [
        MatIconButton,
        MatMenuTrigger,
        MatTooltip,
        MatIcon,
        MatMenu,
        MatMenuItem,
        FlexmonsterPivotModule,
        TranslateModule,
    ]
})
export class FtPivotTableComponent implements StateComponent {
	private _config = inject(AppConfigService);
	private _translate = inject(TranslateService);
	private workflowService = inject(WorkflowService);

	// TODO: Skipped for migration because:
	//  This input overrides a field from a superclass, while the superclass field
	//  is not migrated.
	@Input() data: ViewQuery;
	pvTable: 'wdr' | 'fm' = 'fm';

	readonly pivot = viewChild<any>('pivot');

	dataset: any = [];
	headers = pivotHeader(this);
	config: any = pivotConfig(this);
	private toolbar: any;

	public flexMonsterLicenseKey: string;

	constructor() {
		this.flexMonsterLicenseKey = this._config.flexMonsterLicense;
		if (this.flexMonsterLicenseKey && this.flexMonsterLicenseKey !== '')
			this.pvTable = 'fm';
	}

	loadData() {
		const start = moment(this.data.startDate).format('YYYYMMDD');
		const end = moment(this.data.endDate).format('YYYYMMDD');

		this.workflowService
			.getWorkflowItems(`${start}-${end}`)
			.subscribe(data => {
				if (data && data.length != 0)
					this.pivot()[
						this.pvTable === 'fm' ? 'flexmonster' : 'webDataRocks'
					].updateData({ data });
			});
	}

	onPivotReady(pivot: any): void {
		setTimeout(() => this.loadData());
	}

	onCustomizeCell(cell: any, data: any): void {
		const column = data.measure || null;

		if (data.isGrandTotalRow && data.type !== 'header')
			cell.addClass('ft-grand-total-r');
		if (
			!data.isGrandTotalRow &&
			data.isTotal &&
			data.type !== 'header' &&
			column &&
			column['uniqueName'] === 'paidAmount'
		)
			cell.addClass('ft-total-classic-r');

		if (
			!data.isGrandTotalRow &&
			data.isTotal &&
			data.type !== 'header' &&
			column &&
			column['uniqueName'] === 'totalAmount'
		)
			cell.addClass('ft-total-classic-rt');

		if (
			!data.isGrandTotalRow &&
			data.isTotal &&
			data.type !== 'header' &&
			column &&
			column['uniqueName'] === 'leftAmount'
		)
			cell.addClass('ft-total-classic-rl');
	}

	beforeToolbarCreated(toolbar: any): void {
		this.toolbar = toolbar;
	}

	saveReport() {
		this.pivot()[this.pvTable === 'fm' ? 'flexmonster' : 'webDataRocks'].save(
			'FireRIS_stats_report.json',
			'file'
		);
	}

	exportTo(type: string) {
		const ext = type === 'excel' ? 'xlsx' : type;
		this.pivot()[
			this.pvTable === 'fm' ? 'flexmonster' : 'webDataRocks'
		].exportTo(type, {
			destinationType: 'file',
			filename: 'FireRIS_stats_report.' + ext,
			excelSheetName: 'FireRIS_stats_report',
			pageOrientation: 'portrait',
			header: '<div style="color: #990633; text-align: center">FireRIS - Solution de gestion des centres de radiologie</div>',
			footer: '<a href="https://firethunder.ma/fireris/">https://firethunder.ma/fireris/</a>',
		});
	}

	openFields() {
		this.pivot()[
			this.pvTable === 'fm' ? 'flexmonster' : 'webDataRocks'
		].openFieldsList();
	}

	openOptions() {
		this.toolbar.optionsHandler();
	}

	onReportComplete(): void {
		this.pivot()[this.pvTable === 'fm' ? 'flexmonster' : 'webDataRocks'].off(
			'reportcomplete'
		);
		//this.pivot[this.pvTable === 'fm' ? 'flexmonster': 'webDataRocks'].setReport(this.config);
	}

	openFormatting() {
		this.toolbar.conditionalFormattingHandler();
		this.pivot()[
			this.pvTable === 'fm' ? 'flexmonster' : 'webDataRocks'
		].refresh();
	}

	printReport() {
		this.toolbar.printHandler();
	}

	createFormattingItem() {
		this.toolbar.formatCellsHandler();
	}

	goFullScreen() {
		this.toolbar.fullscreenHandler();
	}
}
