import {
  AfterViewInit,
  Component,
  OnChanges,
  SimpleChanges,
  input
} from '@angular/core';
import ApexCharts from 'apexcharts';

@Component({
    selector: 'ft-chart',
    templateUrl: './ft-chart.component.html',
    styleUrls: ['./ft-chart.component.scss'],
    standalone: true,
})
export class FtChartComponent implements OnChanges, AfterViewInit {
	readonly data = input.required<any[]>();
	readonly labels = input.required<any[]>();
	readonly type = input.required<string>();
	readonly title = input<string>();
	readonly chartId = input<string>('chart');

	private _chart!: ApexCharts;

	private _data!: any;
	private _labels!: any;
	private _title!: any;
	private _type!: any;
	public chartID: any = 'chart';

	ngOnChanges(changes: SimpleChanges): void {
		const { data, labels, type, title, chartId } = changes;

		if (data?.currentValue) this._data = data?.currentValue;
		if (labels?.currentValue) this._labels = labels?.currentValue;
		if (title?.currentValue) this._title = title?.currentValue;
		if (type?.currentValue) this._type = type?.currentValue;
		if (chartId?.currentValue) this.chartID = chartId?.currentValue;

		if (this._chart && this._data) this._chart.updateSeries(this._data);
	}

	private chartOptions = (title: string = '', type: any): any => {
		return {
			chart: {
				type: type,
				width: 460,
			},
			dataLabels: {
				enabled: true,
			},
			plotOptions: {
				bar: {
					borderRadius: 4,
					horizontal: false,
				},
			},
			theme: {
				palette: 'palette7',
			},
			title: {
				text: title,
			},
		};
	};

	ngAfterViewInit(): void {
		setTimeout(() => {
			const barcharts = {
				series: [
					{
						data: [
							400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380,
						],
					},
				],

				xaxis: {
					categories: [
						'South Korea',
						'Canada',
						'United Kingdom',
						'Netherlands',
						'Italy',
						'France',
						'Japan',
						'United States',
						'China',
						'Germany',
					],
				},
			};

			if (this._data && this.chartID && !this._chart) {
				let data = [];
				if (this._type === 'bar') data = [{ data: this._data }];
				else if (this._type === 'pie') data = this._data;

				this._chart = new ApexCharts(
					document.querySelector(`#${this.chartID}`),
					{
						...this.chartOptions(this._title, this._type),
						series: data,
						labels: this._labels,
						xaxis: {
							categories: this._labels,
						},
					}
				);
				this._chart.render();
			}
		}, 400);
	}
}
