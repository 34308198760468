import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { StateComponent, ViewQuery } from '../model';
import { StatisticService } from '../statistic.service';
import { Subscription } from 'rxjs';
import moment from 'moment';
import { AttributeState } from '../../model';
import { TranslateModule } from '@ngx-translate/core';
import { FtChartComponent } from '../../shared/ft-chart/ft-chart.component';
import { MatButtonToggleGroup, MatButtonToggle } from '@angular/material/button-toggle';

@Component({
    selector: 'ft-aet-exam-state',
    templateUrl: './aet-exam-state.component.html',
    styleUrls: ['./aet-exam-state.component.scss'],
    imports: [
        MatButtonToggleGroup,
        MatButtonToggle,
        FtChartComponent,
        TranslateModule,
    ]
})
export class AetExamStateComponent
	implements StateComponent, OnInit, OnDestroy
{
	// TODO: Skipped for migration because:
	//  This input overrides a field from a superclass, while the superclass field
	//  is not migrated.
	@Input() data: ViewQuery;

	private sub: Subscription;
	total: number;
	totalPaid: number;

	public dataset!: AttributeState[];
	private _service = inject(StatisticService);

	public pieData!: any[];
	public pieLabels!: any[];

	ngOnInit() {
		const start = moment(this.data.startDate).format('YYYYMMDD');
		const end = moment(this.data.endDate).format('YYYYMMDD');

		this.sub = this._service
			.getExamsByAet(`${start}-${end}`)
			.subscribe(data => {
				this.dataset = data;

				this.pieData = this.dataset.map(it => it.count);
				this.pieLabels = data.map(it => it.label);

				this.total = this.dataset
					.map(it => it.count)
					.reduce((a, c) => a + c, 0);
				this.totalPaid = parseFloat(
					this.dataset
						.map(it => it.totalPaid)
						.reduce((a, c) => a + c, 0)
						.toFixed(2)
				);
			});
	}

	changeGraphData(key: any) {
		this.pieData = this.dataset.map(it => it[key]);
	}

	ngOnDestroy(): void {
		this.sub.unsubscribe();
	}
}
