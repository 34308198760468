import * as i0 from '@angular/core';
import { EventEmitter, Component, Input, Output, NgModule } from '@angular/core';
import * as Flexmonster from 'flexmonster';
import { CommonModule } from '@angular/common';
class FlexmonsterPivot {
  constructor(el) {
    // events
    this.cellclick = new EventEmitter();
    this.celldoubleclick = new EventEmitter();
    this.chartclick = new EventEmitter();
    this.dataerror = new EventEmitter();
    this.datafilecancelled = new EventEmitter();
    this.dataloaded = new EventEmitter();
    this.datachanged = new EventEmitter();
    this.fieldslistclose = new EventEmitter();
    this.fieldslistopen = new EventEmitter();
    this.filterclose = new EventEmitter();
    this.filteropen = new EventEmitter();
    this.drillthroughopen = new EventEmitter();
    this.exportcomplete = new EventEmitter();
    this.exportstart = new EventEmitter();
    this.drillthroughclose = new EventEmitter();
    this.fullscreen = new EventEmitter();
    this.loadingdata = new EventEmitter();
    this.loadinglocalization = new EventEmitter();
    this.loadingolapstructure = new EventEmitter();
    this.loadingreportfile = new EventEmitter();
    this.localizationerror = new EventEmitter();
    this.localizationloaded = new EventEmitter();
    this.olapstructureerror = new EventEmitter();
    this.olapstructureloaded = new EventEmitter();
    this.openingreportfile = new EventEmitter();
    this.printcomplete = new EventEmitter();
    this.printstart = new EventEmitter();
    this.querycomplete = new EventEmitter();
    this.queryerror = new EventEmitter();
    this.ready = new EventEmitter();
    this.reportchange = new EventEmitter();
    this.reportcomplete = new EventEmitter();
    this.reportfilecancelled = new EventEmitter();
    this.reportfileerror = new EventEmitter();
    this.runningquery = new EventEmitter();
    this.unauthorizederror = new EventEmitter();
    this.update = new EventEmitter();
    this.beforetoolbarcreated = new EventEmitter();
    this.beforegriddraw = new EventEmitter();
    this.aftergriddraw = new EventEmitter();
    this.afterchartdraw = new EventEmitter();
    this.root = el.nativeElement;
  }
  ngOnInit() {
    console.log(Flexmonster);
    this.flexmonster = new window.Flexmonster({
      container: this.root.getElementsByClassName('fm-ng-wrapper')[0],
      componentFolder: this.componentFolder,
      width: this.width,
      height: this.height,
      toolbar: this.toolbar,
      licenseKey: this.licenseKey,
      licenseFilePath: this.licenseFilePath,
      report: this.report,
      global: this.global,
      accessibility: this.accessibility,
      shareReportConnection: this.shareReportConnection,
      customizeAPIRequest: this.customizeAPIRequest,
      customizeCell: this.customizeCell,
      customizeChartElement: this.customizeChartElement,
      customizeContextMenu: this.customizeContextMenu,
      sortFieldsList: this.sortFieldsList,
      cellclick: cell => this.cellclick.next(cell),
      celldoubleclick: cell => this.celldoubleclick.next(cell),
      chartclick: event => this.chartclick.next(event),
      datachanged: event => this.datachanged.next(event),
      dataerror: event => this.dataerror.next(event),
      datafilecancelled: () => this.datafilecancelled.next(),
      dataloaded: () => this.dataloaded.next(),
      fieldslistclose: () => this.fieldslistclose.next(),
      fieldslistopen: () => this.fieldslistopen.next(),
      filterclose: () => this.filterclose.next(),
      filteropen: event => this.filteropen.next(event),
      drillthroughopen: cell => this.drillthroughopen.next(cell),
      exportcomplete: () => this.exportcomplete.next(),
      exportstart: () => this.exportstart.next(),
      drillthroughclose: () => this.drillthroughclose.next(),
      loadingdata: () => this.loadingdata.next(),
      loadinglocalization: () => this.loadinglocalization.next(),
      loadingolapstructure: () => this.loadingolapstructure.next(),
      loadingreportfile: () => this.loadingreportfile.next(),
      localizationerror: () => this.localizationerror.next(),
      localizationloaded: () => this.localizationloaded.next(),
      olapstructureerror: event => this.olapstructureerror.next(event),
      olapstructureloaded: () => this.olapstructureloaded.next(),
      openingreportfile: () => this.openingreportfile.next(),
      printcomplete: () => this.printcomplete.next(),
      printstart: () => this.printstart.next(),
      querycomplete: () => this.querycomplete.next(),
      queryerror: event => this.queryerror.next(event),
      ready: () => this.ready.next(this.flexmonster),
      reportchange: () => this.reportchange.next(),
      reportcomplete: () => this.reportcomplete.next(),
      reportfilecancelled: () => this.reportfilecancelled.next(),
      reportfileerror: () => this.reportfileerror.next(),
      runningquery: () => this.runningquery.next(),
      unauthorizederror: done => this.unauthorizederror.next(done),
      update: () => this.update.next(),
      beforetoolbarcreated: toolbar => this.beforetoolbarcreated.next(toolbar),
      beforegriddraw: event => this.beforegriddraw.next(event),
      aftergriddraw: event => this.aftergriddraw.next(event),
      afterchartdraw: () => this.afterchartdraw.next()
    });
  }
  ngOnDestroy() {
    if (this.flexmonster) {
      this.flexmonster.dispose();
      this.flexmonster = null;
    }
  }
}
FlexmonsterPivot.ɵfac = function FlexmonsterPivot_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FlexmonsterPivot)(i0.ɵɵdirectiveInject(i0.ElementRef));
};
FlexmonsterPivot.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FlexmonsterPivot,
  selectors: [["fm-pivot"]],
  inputs: {
    toolbar: "toolbar",
    licenseKey: "licenseKey",
    licenseFilePath: "licenseFilePath",
    width: "width",
    height: "height",
    componentFolder: "componentFolder",
    report: "report",
    shareReportConnection: "shareReportConnection",
    global: "global",
    accessibility: "accessibility",
    customizeAPIRequest: "customizeAPIRequest",
    customizeCell: "customizeCell",
    customizeChartElement: "customizeChartElement",
    customizeContextMenu: "customizeContextMenu",
    sortFieldsList: "sortFieldsList"
  },
  outputs: {
    cellclick: "cellclick",
    celldoubleclick: "celldoubleclick",
    chartclick: "chartclick",
    dataerror: "dataerror",
    datafilecancelled: "datafilecancelled",
    dataloaded: "dataloaded",
    datachanged: "datachanged",
    fieldslistclose: "fieldslistclose",
    fieldslistopen: "fieldslistopen",
    filterclose: "filterclose",
    filteropen: "filteropen",
    drillthroughopen: "drillthroughopen",
    exportcomplete: "exportcomplete",
    exportstart: "exportstart",
    drillthroughclose: "drillthroughclose",
    fullscreen: "fullscreen",
    loadingdata: "loadingdata",
    loadinglocalization: "loadinglocalization",
    loadingolapstructure: "loadingolapstructure",
    loadingreportfile: "loadingreportfile",
    localizationerror: "localizationerror",
    localizationloaded: "localizationloaded",
    olapstructureerror: "olapstructureerror",
    olapstructureloaded: "olapstructureloaded",
    openingreportfile: "openingreportfile",
    printcomplete: "printcomplete",
    printstart: "printstart",
    querycomplete: "querycomplete",
    queryerror: "queryerror",
    ready: "ready",
    reportchange: "reportchange",
    reportcomplete: "reportcomplete",
    reportfilecancelled: "reportfilecancelled",
    reportfileerror: "reportfileerror",
    runningquery: "runningquery",
    unauthorizederror: "unauthorizederror",
    update: "update",
    beforetoolbarcreated: "beforetoolbarcreated",
    beforegriddraw: "beforegriddraw",
    aftergriddraw: "aftergriddraw",
    afterchartdraw: "afterchartdraw"
  },
  standalone: false,
  decls: 2,
  vars: 0,
  consts: [[2, "width", "100%", "height", "100%"], [1, "fm-ng-wrapper"]],
  template: function FlexmonsterPivot_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0);
      i0.ɵɵelement(1, "div", 1);
      i0.ɵɵelementEnd();
    }
  },
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlexmonsterPivot, [{
    type: Component,
    args: [{
      selector: 'fm-pivot',
      template: '<div style="width:100%;height:100%;"><div class="fm-ng-wrapper"></div></div>'
      //providers: [FlexmonsterPivotService]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, {
    toolbar: [{
      type: Input
    }],
    licenseKey: [{
      type: Input
    }],
    licenseFilePath: [{
      type: Input
    }],
    width: [{
      type: Input
    }],
    height: [{
      type: Input
    }],
    componentFolder: [{
      type: Input
    }],
    report: [{
      type: Input
    }],
    shareReportConnection: [{
      type: Input
    }],
    global: [{
      type: Input
    }],
    accessibility: [{
      type: Input
    }],
    customizeAPIRequest: [{
      type: Input
    }],
    customizeCell: [{
      type: Input
    }],
    customizeChartElement: [{
      type: Input
    }],
    customizeContextMenu: [{
      type: Input
    }],
    sortFieldsList: [{
      type: Input
    }],
    cellclick: [{
      type: Output
    }],
    celldoubleclick: [{
      type: Output
    }],
    chartclick: [{
      type: Output
    }],
    dataerror: [{
      type: Output
    }],
    datafilecancelled: [{
      type: Output
    }],
    dataloaded: [{
      type: Output
    }],
    datachanged: [{
      type: Output
    }],
    fieldslistclose: [{
      type: Output
    }],
    fieldslistopen: [{
      type: Output
    }],
    filterclose: [{
      type: Output
    }],
    filteropen: [{
      type: Output
    }],
    drillthroughopen: [{
      type: Output
    }],
    exportcomplete: [{
      type: Output
    }],
    exportstart: [{
      type: Output
    }],
    drillthroughclose: [{
      type: Output
    }],
    fullscreen: [{
      type: Output
    }],
    loadingdata: [{
      type: Output
    }],
    loadinglocalization: [{
      type: Output
    }],
    loadingolapstructure: [{
      type: Output
    }],
    loadingreportfile: [{
      type: Output
    }],
    localizationerror: [{
      type: Output
    }],
    localizationloaded: [{
      type: Output
    }],
    olapstructureerror: [{
      type: Output
    }],
    olapstructureloaded: [{
      type: Output
    }],
    openingreportfile: [{
      type: Output
    }],
    printcomplete: [{
      type: Output
    }],
    printstart: [{
      type: Output
    }],
    querycomplete: [{
      type: Output
    }],
    queryerror: [{
      type: Output
    }],
    ready: [{
      type: Output
    }],
    reportchange: [{
      type: Output
    }],
    reportcomplete: [{
      type: Output
    }],
    reportfilecancelled: [{
      type: Output
    }],
    reportfileerror: [{
      type: Output
    }],
    runningquery: [{
      type: Output
    }],
    unauthorizederror: [{
      type: Output
    }],
    update: [{
      type: Output
    }],
    beforetoolbarcreated: [{
      type: Output
    }],
    beforegriddraw: [{
      type: Output
    }],
    aftergriddraw: [{
      type: Output
    }],
    afterchartdraw: [{
      type: Output
    }]
  });
})();
class FlexmonsterPivotModule {}
FlexmonsterPivotModule.ɵfac = function FlexmonsterPivotModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FlexmonsterPivotModule)();
};
FlexmonsterPivotModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FlexmonsterPivotModule
});
FlexmonsterPivotModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlexmonsterPivotModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [FlexmonsterPivot],
      exports: [FlexmonsterPivot]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-flexmonster
 */

/**
 * Generated bundle index. Do not edit.
 */

export { FlexmonsterPivot, FlexmonsterPivotModule };
